import React from 'react'
import "./StreamingServices.scss"

import spotify from '../../Badges/subscription-logo-Spotify.png'
import apple from '../../Badges/subscription-logo-ApplePodcasts.png'
import pocketCasts from '../../Badges/subscription-logo-PocketCasts.png'
import podbean from '../../Badges/podbean-badge.png'
export const StreamingServices = () => {
  return (
    <div className='streaming-services'>
      <a href="https://open.spotify.com/show/0owHHq2uudXkmRSg2rzIyK">
         <img src={spotify} alt="spotify"/>
      </a>
      <a href="https://podcasts.apple.com/us/podcast/the-sophisticatedly-ratchet-podcast/id1530764260">
         <img src={apple} alt="apple"/>
      </a>
      <a href="https://pca.st/w40fumjo">
         <img src={pocketCasts} alt="pocketCasts"/>
      </a>
     <a href="https://thesrpodcast.podbean.com/">
        <img src={podbean} alt="podbean"/>
     </a>
    </div>
  )
}
