import React from 'react'
import GroupPic from '../../assets//683A5638.JPG'
import "./Hero.scss"

export const Hero = () => {
  return (
    <div className= 'hero'>
      <img src={GroupPic} alt=""/>
    </div>
  )
}
