import React from 'react'
import "./Overview.scss"
import logo from '../../assets/SR-logo-without-Circle2.jpeg'
import {StreamingServices} from "../StreamingServices/StreamingServices"

export const Overview = () => {
  return (
    <div className='overview' >
      <div className='logo'>
        <img src={logo} alt=""/>
      </div>
      <div className='description'>
        <h1>THE SOPHISTICATEDLY RATCHET PODCAST</h1> 
        
        <h5>The Sophisticatedly Ratchet podcast is a round-table formatted, conversational podcast about any and everything.Six Black Queens and Kings from NYC sharing our views on Black Lifestyle, Culture, Relationships, Religion, and Current Events. Here at SR we strive to give our listeners the REAL in a world of fake. Worldly professionals with class that also know how to switch it up. Now let’s talk our wild shit!
</h5> 
         <StreamingServices />
      </div>
     
    </div>
  )
}
