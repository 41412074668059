import React from "react";
// import logo from "./assets/SR-logo-without-Circle2.jpeg";
// import groupImage from "./assets/683A5638.JPG"
import "./App.scss";
import {Hero} from "./Components/Hero/Hero"
import {Overview} from "./Components/Overview/Overview"
// import {SocialMedia} from "./Components/SocialMedia/SocialMedia"

// import { Container, Row, Col } from "reactstrap";

function App() {
   return (
      <div className="App">
        {/* <SocialMedia /> */}
        <Hero />
        <Overview />

      </div>
   );
}

export default App;
